





import { defineComponent } from '@nuxtjs/composition-api';
import { DcwHomeLayout, DcwLocaleLayout } from '@/components/Layouts';
import { useLocales, usePage } from '@/composables';
import { HomeLayoutContent, Locales, LocaleLayoutContent, Pages } from '@/types';

export default defineComponent({
  name: 'HomePage',
  components: { DcwHomeLayout, DcwLocaleLayout },
  layout: 'Default',
  setup() {
    const { translation, locale } = useLocales();
    const isLocalePage = locale === Locales.DE || locale === Locales.FR;
    usePage({ data: Pages.HOME });

    console.log('14.07.2022');

    const templateContent: HomeLayoutContent = {
      ...translation.homePage,
      joinUs: translation.ui.joinUs
    };

    const localeContent: LocaleLayoutContent = {
      hero: translation.ui.localeBanner
    };

    return { isLocalePage, templateContent, localeContent };
  },
  head: {}
});
